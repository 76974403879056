import React from "react";

export default function SAFeBadge() {
  return (
      <>
<div data-iframe-width="150" data-iframe-height="270" data-share-badge-id="79596dfd-9e3d-4d06-bfbf-f23d30511a76" data-share-badge-host="https://www.youracclaim.com"></div><script type="text/javascript" async src="//cdn.youracclaim.com/assets/utilities/embed.js"></script>
      </>
  );
}

